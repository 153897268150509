
import { SearchParty } from '../../../search/schemas'
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator'
import { ValidationErrors } from '../../domain/ValidationErrors'
import { SearchWizardParty } from './SearchWizardParty'
import { validateChildAges } from './validation/ChildAgesValidator'
import { validateParty } from './validation/PartyValidator'

@Component
export default class GuestsWizard extends Vue {
  @Prop({ default: true })
    enabled?: boolean

  @Prop({ default: false })
    clear: boolean

  @Prop({ required: true, type: Boolean })
    open: boolean

  @Prop({ required: false })
    guests?: SearchParty

  @Prop({ required: true, type: Boolean })
    showSummary: boolean

  validationErrors: ValidationErrors = {
    party: '',
    childAges: '',
  }
  guestsModel: SearchWizardParty

  created() {
    this.populateModel()
  }

  get guestsWithPlaceholder(): SearchWizardParty {
    return {
      adults: this.guests ? this.guests.adults : 2,
      childAges: this.guests ? [...this.guests.childAges] : [],
      children: this.guests ? this.guests.childAges.length : 0,
    }
  }

  changeGuests(newParty: SearchWizardParty) {
    this.guestsModel = { ...newParty }
    if (!this.isValid) this.validateGuestsModel()
  }

  apply() {
    this.validateGuestsModel()
    if (this.isValid) {
      this.$emit('changeGuests', this.guestsModel)
    }
  }

  cancel() {
    this.populateModel()
    this.clearValidationErrors()
    this.$emit('cancel')
  }

  populateModel() {
    this.guestsModel = this.guestsWithPlaceholder
  }

  @Watch('enabled')
  onEnabledChange() {
    if (this.enabled) {
      this.showMessage = false
    }
  }

  showMessageStore = false

  get showMessage() {
    return this.showMessageStore && !this.clear
  }

  set showMessage(show) {
    this.showMessageStore = show
  }

  doOpen() {
    if (this.enabled) {
      this.populateModel()
      this.$emit('open')
    } else {
      this.showMessage = true
    }
  }

  validateGuestsModel() {
    const { adults, childAges, children } = this.guestsModel
    this.clearValidationErrors()
    this.validationErrors.party = validateParty(adults, children)
    this.validationErrors.childAges = validateChildAges(childAges, children)
  }

  clearValidationErrors() {
    this.validationErrors = {
      party: '',
      childAges: '',
    }
  }

  get isValid() {
    return (
      this.validationErrors.party === '' &&
      this.validationErrors.childAges === ''
    )
  }
}
