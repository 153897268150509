export function validateChildAges(
  childAges: number[],
  children: number,
): string {
  const errorMessage =
    'Please enter valid ages for all the children in your party.'

  function isValid() {
    // children is a count of children (children number) selected by user
    // childAges comes as an array with all options from wizard (ages selected).
    // That means it can contain more options than children number.
    // We need to cut it to size of children and filter nulls.
    return (
      childAges.slice(0, children).filter((a) => a >= 0).length === children
    )
  }

  return !isValid() ? errorMessage : ''
}
