var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { useSearchWizardStore } from '#imports';
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator';
var AccommodationWizard = (_dec = Component({
  setup() {
    var searchWizardStore = useSearchWizardStore(usePinia());
    return {
      searchWizardStore
    };
  }
}), _dec2 = Prop({
  required: true,
  type: Array
}), _dec3 = Prop({
  required: true,
  type: Object
}), _dec4 = Prop({
  required: true,
  type: Boolean
}), _dec5 = Prop({
  required: true,
  type: Boolean
}), _dec6 = Prop({
  required: false,
  type: Boolean,
  default: true
}), _dec7 = Prop({
  required: false,
  type: Boolean,
  default: false
}), _dec8 = Prop({
  required: false,
  type: Boolean,
  default: true
}), _dec9 = Prop({
  required: false
}), _dec10 = Watch('categoryIds'), _dec(_class = (_class2 = class AccommodationWizard extends Vue {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "categoryIds", _descriptor, this);
    _initializerDefineProperty(this, "searchFilters", _descriptor2, this);
    _initializerDefineProperty(this, "open", _descriptor3, this);
    _initializerDefineProperty(this, "showSummary", _descriptor4, this);
    _initializerDefineProperty(this, "enabled", _descriptor5, this);
    _initializerDefineProperty(this, "isSearchResults", _descriptor6, this);
    _initializerDefineProperty(this, "showCounts", _descriptor7, this);
    _initializerDefineProperty(this, "allowedCategoryIds", _descriptor8, this);
    this.categoryIdsModel = [];
  }
  get counts() {
    var _this$searchWizardSto;
    return (_this$searchWizardSto = this.searchWizardStore.counts) === null || _this$searchWizardSto === void 0 ? void 0 : _this$searchWizardSto.categoriesCounts;
  }
  created() {
    this.populateModel();
  }
  updateCategoryIdsModel() {
    this.populateModel();
  }
  changeCategoryIds(newCategoryIds) {
    this.categoryIdsModel = [...newCategoryIds];
  }
  doOpen() {
    if (this.enabled) {
      this.populateModel();
      this.$emit('open');
    }
  }
  populateModel() {
    this.categoryIdsModel = [...this.categoryIds];
  }
  apply() {
    this.$emit('changeCategoryIds', this.categoryIdsModel);
    this.$emit('close');
  }
  cancel() {
    this.resetModel();
    this.$emit('cancel');
  }
  resetModel() {
    this.categoryIdsModel = [...this.categoryIds];
    this.$emit('close');
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "categoryIds", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "searchFilters", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "open", [_dec4], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "showSummary", [_dec5], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "enabled", [_dec6], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "isSearchResults", [_dec7], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "showCounts", [_dec8], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "allowedCategoryIds", [_dec9], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "updateCategoryIdsModel", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "updateCategoryIdsModel"), _class2.prototype)), _class2)) || _class);
export { AccommodationWizard as default };