
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { ValidationErrors } from '../../domain/ValidationErrors'
import { SearchWizardParty } from './SearchWizardParty'

@Component<GuestsWizardBody>({
  head() {
    return {
      bodyAttrs: {
        class: this.$isDesktop ? '' : 'noscroll',
      },
    }
  },
})
export default class GuestsWizardBody extends Vue {
  @Prop({ required: true })
    guests: SearchWizardParty

  @Prop()
    validationErrors?: ValidationErrors

  adults = 2
  minAdultAge = [...Array(19).keys()]
  children = 0
  childAges: number[] = []

  created() {
    this.adults = this.guests.adults
    this.children = this.guests.childAges.length
    this.childAges = [...this.guests.childAges]
  }

  get sanitizedGuests(): SearchWizardParty {
    const childAges =
      this.childAges.length > this.children
        ? this.childAges.slice(0, this.children)
        : this.childAges
    return {
      adults: this.adults,
      childAges,
      children: this.children,
    }
  }

  get partyErrors() {
    return this.validationErrors?.party
  }

  get childAgesErrors() {
    return this.validationErrors?.childAges
  }

  get errors() {
    return this.partyErrors || this.childAgesErrors
  }

  submitGuests() {
    this.$emit('changeGuests', this.sanitizedGuests)
  }
}
