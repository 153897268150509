import { render, staticRenderFns } from "./DatesWizard.vue?vue&type=template&id=7c0b5482"
import script from "./DatesWizard.vue?vue&type=script&lang=ts"
export * from "./DatesWizard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DatesWizardSummaryDesktop: require('/app/components/datepickers/wizard/DatesWizardSummaryDesktop.vue').default,DatesWizardSummary: require('/app/components/datepickers/wizard/DatesWizardSummary.vue').default,DatesWizardKeys: require('/app/components/datepickers/wizard/DatesWizardKeys.vue').default,DatesWizardBody: require('/app/components/datepickers/wizard/DatesWizardBody.vue').default,WizardSection: require('/app/apps/search-wizard/wizards/generic/WizardSection.vue').default})
