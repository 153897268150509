
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component({
  components: {
    portal: () => {
      if (process.client) {
        return import(
          /* webpackChunkName: "vue-portal", webpackMode: "lazy" */ 'portal-vue'
        ).then(({ Portal }) => Portal)
      } else {
        return
      }
    },
  },
})
export default class WizardSection extends Vue {
  @Prop({ required: true, type: Boolean })
    open: boolean

  @Prop({ required: true, type: Boolean })
    showSummary: boolean

  @Prop({ required: false, type: Boolean, default: true })
    showApplyButton: boolean

  @Prop({ required: false, type: Boolean, default: true })
    showCancelButton: boolean

  mounted() {
    window.addEventListener('keydown', this.keyPressHandler, { passive: true })
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyPressHandler)
  }

  escapeKeyHandler() {
    this.$emit('cancel')
  }

  keyPressHandler(event: KeyboardEvent) {
    if (this.open && event.key === 'Escape') {
      this.escapeKeyHandler()
    }
  }
}
