import { render, staticRenderFns } from "./AccommodationWizardSummary.vue?vue&type=template&id=55a92114"
import script from "./AccommodationWizardSummary.vue?vue&type=script&lang=ts"
export * from "./AccommodationWizardSummary.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,SelectedCategories: require('/app/apps/categories/components/SelectedCategories.vue').default})
