import { render, staticRenderFns } from "./GuestsWizard.vue?vue&type=template&id=d35d5a66"
import script from "./GuestsWizard.vue?vue&type=script&lang=ts"
export * from "./GuestsWizard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GuestsWizardSummary: require('/app/apps/search-wizard/wizards/guests/GuestsWizardSummary.vue').default,GuestsWizardBody: require('/app/apps/search-wizard/wizards/guests/GuestsWizardBody.vue').default,WizardSection: require('/app/apps/search-wizard/wizards/generic/WizardSection.vue').default})
