
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { CampsiteCategory } from '~/utility/types/CampsiteCategory'
import { Nullable } from '~/utility/types/Nullable'
import { ArriveDepartIso } from '../../../search/types'
import { AvailabilityResults } from '../../availability/schemas'
import { OpeningDatesRange } from '../../types'
import WizardSection from '../generic/WizardSection.vue'

@Component({
  components: {
    WizardSection,
  },
})
export default class DatesWizard extends Vue {
  @Prop()
    slug?: boolean

  @Prop({ required: true, type: Boolean })
    open: boolean

  @Prop()
    dates?: ArriveDepartIso

  @Prop()
    categories?: CampsiteCategory[]

  @Prop()
    openingDates?: OpeningDatesRange[]

  @Prop()
    availability?: AvailabilityResults

  @Prop({ required: true, type: Boolean })
    showSummary: boolean

  @Prop({ default: false })
    required?: boolean

  @Prop({ default: false })
    highlightErrors: boolean

  datesModel: Nullable<ArriveDepartIso> = { arrive: null, depart: null }

  created() {
    this.populateModel()
  }

  populateModel() {
    this.datesModel = this.dates
      ? { ...this.dates }
      : { arrive: null, depart: null }
  }

  changeDates(newDates: Nullable<ArriveDepartIso>) {
    this.datesModel = { ...newDates }
  }

  apply() {
    this.$emit('changeDates', this.datesModel)
  }

  doOpen() {
    this.populateModel()
    this.$emit('open')
  }

  get hasDates() {
    return this.dates && this.dates.arrive && this.dates.depart
  }
}
