
import { SearchParty } from '../../../search/schemas'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class GuestsWizardSummary extends Vue {
  @Prop({ required: true, type: Object })
    guests: SearchParty

  @Prop()
    disabled: boolean

  @Prop()
    showMessage: boolean

  get hasChildren(): boolean {
    return this.guests.childAges.length > 0
  }
}
