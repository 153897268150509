
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import CATEGORY_MAP from '~/json/catRoot.json'

@Component
export default class AccommodationWizardSummary extends Vue {
  @Prop({ required: true, type: Array })
    categoryIds: string[]

  @Prop({ required: false, type: Boolean, default: false })
    isSearchResults: boolean

  get selectedCategoryIdsOrAll() {
    return this.categoryIds.length
      ? this.categoryIds
      : Object.keys(CATEGORY_MAP)
  }

  get showAllCategories() {
    return (
      this.isSearchResults && this.$isDesktop && this.categoryIds.length === 0
    )
  }
}
